var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('h2',[_vm._v(_vm._s(_vm.$t("analytics.trending_items.title")))]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.userConf.date_range.startDate))+" - "+_vm._s(_vm._f("moment")(_vm.userConf.date_range.endDate))+" ")])]),_c('div',{staticClass:"col-xl-8 "},[_c('UsStatsConfiguration')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-wrap py-3"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("analytics.trending_items.list.table.title"))+" ")])]),_c('div',{staticClass:"card-toolbar"})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer",attrs:{"id":"kt_datatable_wrapper"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"dense":"","headers":_vm.headers,"items":_vm.trending_items.table,"options":_vm.options,"loading":_vm.loading,"search":_vm.search,"loading-text":_vm.$t('analytics.trending_items.list.table.loading')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                        name: 'trending-item',
                        query: { id: item.id }
                      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.result_setting_type_id",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.result_setting_type_id === "1" ? "Autocomplete" : "Search")+" ")]}},{key:"item.queries",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.queries).toLocaleString())+" ")]}},{key:"item.unique_queries",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.unique_queries).toLocaleString())+" ")]}},{key:"item.avg_position",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.avg_position).toLocaleString("cs-CZ", { maximumFractionDigits: 2 }))+" ")]}},{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex align-items-center position-relative my-1"},[_c('span',{staticClass:"svg-icon svg-icon-1 position-absolute ms-6"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('rect',{attrs:{"opacity":"0.5","x":"17.0365","y":"15.1223","width":"8.15546","height":"2","rx":"1","transform":"rotate(45 17.0365 15.1223)","fill":"black"}}),_c('path',{attrs:{"d":"M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z","fill":"black"}})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-solid w-250px ps-14",attrs:{"type":"text","placeholder":_vm.$t(
                              'analytics.trending_items.list.table.search_field'
                            )},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',{staticClass:"d-flex align-items-center position-relative my-1 ml-2"},[_c('v-chip-group',{staticClass:"py-2",attrs:{"multiple":""},model:{value:(_vm.activeFilters.collection_name),callback:function ($$v) {_vm.$set(_vm.activeFilters, "collection_name", $$v)},expression:"activeFilters.collection_name"}},[_vm._l((_vm.filters.collection_name),function(item){return [_c('v-chip',{key:("" + item),attrs:{"value":item,"filter":_vm.activeFilters.collection_name.includes(item),"ripple":false}},[_vm._v(" "+_vm._s(item)+" ")])]})],2)],1)])]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.nodata"))+" ")]},proxy:true}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }